import * as React from 'react';
import BlackHeader from 'components/swipr/BlackHeader';
import DescFooter from 'components/DescFooter';
import Meta from 'components/Meta';

import 'styles/index.scss';

const PrivacyPolicy = () => {
  return (
    <>
      <Meta titleSuffix={'Privacy Policy'} />
      <main className={'desc'}>
        <BlackHeader text={'Privacy Policy'} />
        <div className={'desc-content'}>
          <p>
            <strong>I. Important introduction to our privacy policy</strong>
            <br />
            This Privacy Policy explains how BeFriend Inc. (“BeFriend” “we”,
            “our”, or “us”), the company that operates BeFriend, collects, uses,
            shares, and stores your information when you use the BeFriend
            website and/or the mobile applications that link to this Privacy
            Policy (“Services”). When you use our Services, you are agreeing to
            our rules and policies, including this Privacy Policy and Terms of
            Use, and you consent to our collecting, processing and storing your
            information as described below. You must not access or use the
            Services if you do not agree with our Privacy Policy. You should
            also read our Terms of Service because they also apply to your use
            of the Services.
          </p>
          <p>
            <strong>II. Information we collect</strong>
            <br />
            There are three basic categories of information we collect:
          </p>
          <ul>
            <li>Information you choose to give us.</li>
            <li>Information we get when you use our Services.</li>
            <li>Information we get from third parties.</li>
          </ul>
          Here’s a little more detail on each of these categories.
          <br />
          <p>
            <strong>Information You Choose to Give Us</strong>
            <br />
            When you interact with our Services, we collect the information that
            you choose to share with us. For example, most of our Services
            require you to set up a basic BeFriend account, so we may collect a
            few important details about you, such as: name, password, a phone
            number, gender, and your date of birth among others. You’ll also
            provide us whatever information you send through our Services, such
            as messages to our users. Keep in mind that the users you send
            messages and any other content to can always save that content or
            copy it outside the app. So, the same common sense that applies to
            the internet at large applies to BeFriend as well: Don’t send
            messages or share content that you wouldn’t want someone to save or
            share. When you contact customer support or communicate with us in
            any other way, we’ll collect whatever information you volunteer as
            well. Also, BeFriend provides various ways to create an account such
            as through phone number, Facebook, or Snapchat. If you sign-up with
            phone number, you are giving us your phone number information. If
            you sign-up with your Facebook account, we will receive certain
            information about you from Facebook such as name, profile picture,
            email, birthday, and interests. If you sign-up with your Snapchat
            account, we will also receive certain information about you from
            Snapchat such as name and Bitmoji.
          </p>
          <p>
            <strong>Information We Get When You Use Our Services</strong>
            <br />
            When you use our Services, we collect information about which of
            those Services you’ve used and how you’ve used them. Here’s a fuller
            explanation of the types of information we may collect when you use
            our Services:
          </p>
          <ul>
            <li>
              <strong>Usage Information.</strong> We collect information about
              your activity through our Services. For example, we may collect
              information about:
            </li>
            <ul>
              <li>
                how you interact with our Services, such as which pages you view
                the most, how often you use our Services, etc.
              </li>
              <li>
                how you communicate with other users on BeFriend, such as their
                names, the time and date of your communications, the number of
                messages you exchange with your friends, which friends you
                exchange messages with the most, and your interactions with
                messages (such as when you open a message).
              </li>
            </ul>
            <li>
              <strong>Content Information.</strong> We collect content you
              create on our Services and information about the content you
              create or provide, such as if the recipient has viewed the content
              and the metadata that is provided with the content.
            </li>
            <li>
              <strong>Device Information.</strong> We collect information from
              and about the devices you use.
            </li>
            <li>
              <strong>Camera and Photos.</strong> Some of our Services require
              us to collect images and other information from your device’s
              camera and photos.
            </li>
            <li>
              <strong>Location Information.</strong> When you use our Services
              we may collect information about your location with your
              permission. With your permission, we may also collect information
              about your precise location using methods that include GPS,
              wireless networks, cell towers, Wi-Fi access points, and other
              sensors, such as gyroscopes, accelerometers, and compasses.
            </li>
            <li>
              <strong>
                Information Collected by Cookies and Other Technologies.
              </strong>{' '}
              Like most online services and mobile applications, we may use
              cookies and other technologies, such as web beacons, web storage,
              and unique advertising identifiers, to collect information about
              your activity, browser, and device. Most web browsers are set to
              accept cookies by default. If you prefer, you can usually remove
              or reject browser cookies through the settings on your browser or
              device. Keep in mind, though, that removing or rejecting cookies
              could affect the availability and functionality of our services.
            </li>
            <li>
              <strong>Log Information.</strong> We also collect log information
              when you use our website. That information includes, among other
              things:
            </li>
            <ul>
              <li>details about how you’ve used our services.</li>
              <li>
                device information, such as your web browser type and language.
              </li>
              <li>access times and pages viewed.</li>
            </ul>
            <li>
              <strong>In App Purchase.</strong> When you subscribe to a paid
              service or make any other type of purchase or make a purchase
              directly from us (rather than through a platform such as iOS or
              Android), you provide us or our payment information.
            </li>
          </ul>
          <p>
            Keeping your information private and secure is of our utmost
            importance. We take deep measures to ensure that we only collect
            information that you explicitly give us permission to. We will never
            try to trick you into giving us information nor will we abuse the
            information you give us. While we collect information to help you
            take full advantage of our product features, we understand if you
            feel uncomfortable giving us permission. If so, you are encouraged
            to deny giving us permission. You also have the ability to delete
            any information you have given us from our servers; for example, if
            you have given us permission to your Contacts, but no longer wish to
            have them stored in our servers, let us know at support@swipr.cc.
            You can also delete your account or any other information from our
            servers by reaching us at that email.
          </p>
          <p>
            <strong>Information We Collect from Third Parties</strong>
            <br />
            We may collect information about you from other users and third
            parties. For example, if another user uploads their contact list, we
            may combine information from that user’s contact list with other
            information we have collected about you.
          </p>
          <p>
            <strong>III. How we use information</strong>
            <br />
            The information we collect is used to provide you with an amazing
            set of products and services that we relentlessly improve. Here are
            some ways we do that:
          </p>
          <ul>
            <li>
              develop, operate, improve, deliver, maintain, and protect our
              products and services.
            </li>
            <li>
              enhance the safety and security of our products and services.
            </li>
            <li>
              verify your identity and prevent fraud or other unauthorized or
              illegal activity.
            </li>
            <li>
              use the information we’ve collected from cookies and other
              technology to enhance our services and your experience with them.
            </li>
            <li>enforce our Terms of Service and other usage policies.</li>
          </ul>
          <p>
            <strong>IV. How we share information</strong>
            <br />
            We may share information about you in the following ways:
          </p>
          <ul>
            <li>
              With other users on BeFriend. We may share the following
              information with other users:
            </li>
            <ul>
              <li>
                information about you, such as your name, interests, bio,
                gender, among others.
              </li>
              <li>any additional information you have directed us to share.</li>
              <li>content you post or send.</li>
            </ul>
            <li>With our business partners, and the general public.</li>
            <li>We may share the following information:</li>
            <ul>
              <li>public information like your name and album pictures.</li>
              <li>content that you have made public</li>
            </ul>
            <li>
              With third parties. We may share your information with the
              following third parties:
            </li>
            <ul>
              <li>
                With service providers. We may share information about you with
                service providers who perform services on our behalf.
              </li>
              <li>
                With business partners. We may share information about you with
                business partners that provide services and functionality.
              </li>
              <li>
                With third parties for legal reasons. We may share information
                about you if we reasonably believe that disclosing the
                information is needed to:
              </li>
              <ul>
                <li>
                  。comply with any valid legal process, governmental request,
                  or applicable law, rule, or regulation.
                </li>
                <li>
                  。investigate, remedy, or enforce potential Terms of Service
                  violations. protect the rights, property, and safety of us,
                  our users, or others.
                </li>
                <li>。detect and resolve any fraud or security concerns.</li>
              </ul>
            </ul>

            <li>
              With third parties as part of a merger or acquisition. If BeFriend
              gets involved in a merger, asset sale, financing, liquidation or
              bankruptcy, or acquisition of all or some portion of our business
              to another company, we may share your information with that
              company before and after the transaction closes.
            </li>
          </ul>
          <p>
            We may also share with third parties—such as advertisers—aggregated,
            non-personally identifiable, or de-identified information.
          </p>
          <p>
            <strong>V. Third-party Content and Integrations</strong>
            <br />
            Our services may also contain third-party links and search results,
            include third-party integrations, or offer a co-branded or
            third-party-branded service. Through these links, third-party
            integrations, and co-branded or third-party-branded services, you
            may be providing information (including personal information)
            directly to the third party, us, or both. You acknowledge and agree
            that we are not responsible for how those third parties collect or
            use your information. As always, we encourage you to review the
            privacy policies of every third-party service that you visit or use,
            including those third parties you interact with through our
            services.
          </p>
          <p>
            <strong>VI. Revisions to the privacy policy</strong>
            <br />
            We may change this Privacy Policy from time to time.
          </p>
          <br />
          <br />
          <br />
        </div>
        <DescFooter />
      </main>
    </>
  );
};

export default PrivacyPolicy;
